require( "./modules/slider.js" );
require( "./modules/mobile-menu.js" );
require( "./modules/gallery.js" );
require( "./modules/forms.js" );
require( "./modules/location.js" );
require( "./modules/projects.js" );
require("./modules/logging.js");

(function(){

  var $btn = $('.js-sub-toggle');
  var $menu = $('.js-sub-nav');

  $btn.on('click', toggleMenu);

  function toggleMenu(e){
    e.preventDefault();
    $menu.slideToggle();
  }

})();

var form = $('.js-contactform');
form.on( 'change keyup keydown paste cut', 'textarea', function (){
    $(this).height(0).height(this.scrollHeight);
}).find( 'textarea' ).change();
