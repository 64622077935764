var magnificPopup = require('magnific-popup');
var gallery = $('.js-gallery');
var articleImg = $('.js-article-img');


gallery.magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled : true
  }
});

articleImg.magnificPopup({
  type: 'image'
});
