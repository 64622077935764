var toggle = $('.js-toggle');
var mobileMenu = $('.js-menu');
var socialMenu = $('.js-social-menu');

toggle.on('click', function(event) {
  event.preventDefault();
  $(this).toggleClass('isToggled');
  mobileMenu.toggleClass('isOpened');
  socialMenu.toggleClass('isOpened');
});
