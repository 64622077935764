var toggle = $( '.js-block-toggle' );
var projectsBlock = $( '.js-block-nav' );
var infoBlock = $( '.js-info' );
var infoBlockToggle = $( '.js-info-toggle' );
var closeInfo = $( '.js-info-close' );

toggle.on( 'click', function( event ) {
  $( this ).toggleClass( 'isOpen' );
  projectsBlock.toggleClass( 'isToggled' );
  infoBlockToggle.toggleClass('isToggled');

  if ( projectsBlock.hasClass( 'isToggled' ) ) {
    infoBlock.css( 'transform', 'translateY(-' + projectsBlock.height() + 'px)' );

  } else {

    infoBlock.css( 'transform', 'translateY(0)' );
  }
} );

infoBlockToggle.on( 'click', function( event ) {
  infoBlockToggle.addClass( 'isToggled' );
  infoBlock.addClass( 'isToggled' );
  toggle.hide();
} );

closeInfo.on( 'click', function( event ) {
  toggle.show();
  event.preventDefault();
  infoBlock.removeClass( 'isToggled' );
  infoBlockToggle.removeClass('isToggled');
} );
