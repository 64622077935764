var slick = require( 'slick-carousel' );

var bgSlider = $( '.js-slider' );
var headerSlider = $( '.js-subslider' );
var projectSlider = $( '.js-projectslider' );
var projectNavSlider = $( '.js-project-nav' );
var projectSlides = $( '.js-project_slide' );

var currentIndex;

for ( var i = 0; i < projectSlides.length; i++ ) {
  if ( $( projectSlides[ i ] ).hasClass( 'active-slide' ) ) {
    var currentIndex = i;
  }
}


bgSlider.slick( {
  arrows: false,
  fade: true
} );

headerSlider.slick( {
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 5000
} );

projectSlider.slick( {
  arrows: false,
  fade: true
} );

projectNavSlider.slick( {
  arrows: true,
  nextArrow: '<div class="arrow arrow-right js-right-arrow"></div>',
  prevArrow: '<div class="arrow arrow-left js-left-arrow"></div>',
  appendArrows: $( '.js-block-nav' ),
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: currentIndex,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
} );
